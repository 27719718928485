<template>
  <b-row>
    <b-col sm="12" md="4">
      <iq-card>
        <template v-slot:headerTitle>
          <h5>{{ $t("Headbar.ad6@search_result") }}</h5>
        </template>
        <div class="pcTab">
          <h4 class="left-filter">{{ $t("Headbar.a43@filter") }}</h4>
          <ul class="p-0 m-0 pb-2 icon-box-left">
            <li v-for="(item, index) in leftList" :key="index" @click="handleSearchType(item)"
              :style="item.name == currentType ? 'background: #f5fbff' : ''">
              <div class="icon-box" :style="item.name == currentType ? 'background: #50b5ff' : ''">
                <i :class="item.icon"></i>
              </div>
              <span>{{ $t(item.title) }}</span>
            </li>
          </ul>
        </div>
        <div class="phoneTab">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="Filter" name="1">
              <div>
                <ul class="p-0 m-0 pb-2 icon-box-left">
                  <li v-for="(item, index) in leftList" :key="index" @click="handleSearchType(item)"
                    :style="item.name == currentType ? 'background: #f5fbff' : ''">
                    <div class="icon-box" :style="item.name == currentType ? 'background: #50b5ff' : ''">
                      <i :class="item.icon"></i>
                    </div>
                    <span>{{ $t(item.title) }}</span>
                  </li>
                </ul>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </iq-card>
    </b-col>
    <b-col sm="12" md="8" id="search_container" v-loading="loading">
      <!-- User -->
      <iq-card v-if="resultData.User && resultData.User.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Headbar.f31@user") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box" v-for="(item, index) in resultData.User" :key="index">
              <div class="user-left d-flex align-items-center"
                @click="$router.push(`/profile?user_id=${item.user_id}`)">
                <div class="user-img cursor-pointer">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.avatar" /> -->
                  <el-avatar :size="60" :src="item.avatar" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name cursor-pointer">
                  <h3>{{ item.nick_name }}</h3>
                </div>
              </div>
              <b-button pill variant="outline-primary" v-if="!item.is_friend"
                @click="handleClickOperating('User', item)">+ {{ $t("Headbar.d63@friend") }}</b-button>
              <b-button pill class="mt-4 selectedActive" v-else>
                <i class="ri-check-line"></i>{{ $t("Headbar.d63@friend") }}
              </b-button>
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- InterstClub -->
      <iq-card v-if="resultData.InterstClub && resultData.InterstClub.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Sidebar.820@interest_club") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box" v-for="(item, index) in resultData.InterstClub" :key="index">
              <div class="user-left d-flex align-items-center" @click="$router.push(`/group-detail?id=${item.id}`)">
                <div class="user-img cursor-pointer">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.headimg" /> -->
                  <el-avatar :size="60" :src="item.headimg" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name cursor-pointer">
                  <h3 class="ellipsis_one" :title="item.group_name">{{ item.group_name }}</h3>
                </div>
              </div>
              <b-button pill variant="outline-primary" v-if="!item.is_join"
                @click="handleClickOperating('InterstClub', item)">+ {{ $t("Headbar.126@join") }}</b-button>
              <b-button pill class="mt-4 selectedActive" v-else>
                <i class="ri-check-line"></i>{{ $t("Headbar.126@join") }}
              </b-button>
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- Channel -->
      <iq-card v-if="resultData.Channel && resultData.Channel.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Headbar.08a@channel") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box" v-for="(item, index) in resultData.Channel" :key="index">
              <div class="user-left d-flex align-items-center"
                @click="$router.push(`/organization-detail?channel_id=${item.id}`)">
                <div class="user-img cursor-pointer">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.image" /> -->
                  <el-avatar :size="60" :src="item.image" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name cursor-pointer">
                  <h3>{{ item.title }}</h3>
                </div>
              </div>
              <b-button pill variant="outline-primary" v-if="!item.is_follow"
                @click="handleClickOperating('Channel', item)">+ Follow</b-button>
              <b-button pill class="mt-4 selectedActive" v-else>
                <i class="ri-check-line"></i>{{ $t("Headbar.0c2@follow") }}
              </b-button>
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- Post -->
      <iq-card v-if="resultData.Post && resultData.Post.length" id="search_post">
        <ul class="p-0 m-0">
          <li v-for="(item, index) in resultData.Post" :key="index">
            <SocialPost :post="item" />
          </li>
        </ul>
      </iq-card>
      <!-- Course -->
      <iq-card v-if="resultData.Course && resultData.Course.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Headbar.c5c@course") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box cursor-pointer" v-for="(item, index) in resultData.Course" :key="index"
              @click="JumpDetails(item,'Course')">
              <div class="user-left d-flex align-items-center">
                <div class="user-img">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.thump" /> -->
                  <el-avatar :size="60" :src="item.thump" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name">
                  <h3>{{ item.title }}</h3>
                  <p class="mb-0">{{ item.description }}</p>
                </div>
              </div>
              <p class="m-0">{{ item.start_time }}</p>
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- Meeting -->
      <!-- <iq-card v-if="resultData.Meeting.length">
        <div class="search-event">
          <div class="avatar_box_self">
            <img class="avatar-70" src="@/assets/images/user/user-05.jpg" />
          </div>
          <div class="event-info">
            <span>2020.08.20 10:00</span>
            <h3>XXX Event</h3>
            <p class="m-0">Online Event · 15,000 Enrolled</p>
          </div>
        </div>
      </iq-card> -->
      <iq-card v-if="resultData.Meeting && resultData.Meeting.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Headbar.a4d@meeting") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box cursor-pointer" v-for="(item, index) in resultData.Meeting" :key="index"
              @click="JumpDetails(item,'Meeting')">
              <div class="user-left d-flex align-items-center">
                <div class="user-img">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.image" /> -->
                  <el-avatar :size="60" :src="item.image" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name">
                  <h3>{{ item.title }}</h3>
                </div>
              </div>
              <!-- <b-button pill variant="outline-primary" v-if="!item.is_join"
                @click="handleClickOperating('Meeting', item)">+ {{ $t("Headbar.3ba@acceding") }}</b-button>
              <b-button pill class="mt-4 selectedActive" v-else>
                <i class="ri-check-line"></i>{{ $t("Headbar.3ba@acceding") }}
              </b-button> -->
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- Event -->
      <!-- <ul
        class="m-0 p-0"
        v-if="resultData.length > 0 && resultData[6].data.length > 0"
      >
        <li v-for="(item,index) in resultData[6].data" :key="index">
          <iq-card>
            <div class="search-event">
              <div class="avatar_box_self">
                <img class="avatar-70" :src="item.image" />
              </div>
              <div class="event-info">
                <span>2020.08.20 10:00</span>
                <h3>XXX Event</h3>
                <p class="m-0">Online Event · 15,000 Enrolled</p>
              </div>
            </div>
          </iq-card>
        </li>
      </ul> -->
      <iq-card v-if="resultData.Event && resultData.Event.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Headbar.c60@event") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box cursor-pointer" v-for="(item, index) in resultData.Event" :key="index"
              @click="JumpDetails(item,'Event')">
              <div class="user-left d-flex align-items-center">
                <div class="user-img">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.image" /> -->
                  <el-avatar :size="60" :src="item.image" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name">
                  <h3>{{ item.title }}</h3>
                </div>
              </div>
              <!-- <b-button pill variant="outline-primary" v-if="!item.is_join"
                @click="handleClickOperating('Event', item)">+ {{ $t("Headbar.3ba@acceding") }}</b-button>
              <b-button pill class="mt-4 selectedActive" v-else>
                <i class="ri-check-line"></i>{{ $t("Headbar.3ba@acceding") }}
              </b-button> -->
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- Project -->
      <!-- <iq-card>
        <div class="search-event">
          <div class="avatar_box_self">
            <img class="avatar-70" src="@/assets/images/user/user-05.jpg" />
          </div>
          <div class="event-info">
            <span>2020.08.20 10:00</span>
            <h3>XXX Project</h3>
            <p class="m-0">Online Event · 15,000 Enrolled</p>
          </div>
        </div>
      </iq-card> -->
      <iq-card v-if="resultData.Project && resultData.Project.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Headbar.015@project") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box cursor-pointer" @click="JumpDetails(item,'Project')"
              v-for="(item, index) in resultData.Project" :key="index">
              <div class="user-left d-flex align-items-center">
                <div class="user-img">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.image" /> -->
                  <el-avatar :size="60" :src="item.image" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name">
                  <h3>{{ item.title }}</h3>
                </div>
              </div>
              <!-- <b-button pill variant="outline-primary" v-if="!item.is_join"
                @click.stop="handleClickOperating('Project', item)">+ {{ $t("Headbar.3ba@acceding") }}</b-button>
              <b-button pill class="mt-4 selectedActive" v-else>
                <i class="ri-check-line"></i>{{ $t("Headbar.3ba@acceding") }}
              </b-button> -->
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <iq-card v-if="resultData.Program && resultData.Program.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">Program</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box cursor-pointer" @click="JumpDetails(item,'Program')"
              v-for="(item, index) in resultData.Program" :key="index">
              <div class="user-left d-flex align-items-center">
                <div class="user-img">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.image" /> -->
                  <el-avatar :size="60" :src="item.image" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name">
                  <h3>{{ item.title }}</h3>
                </div>
              </div>
              <!-- <b-button pill variant="outline-primary" v-if="!item.is_join"
                @click.stop="handleClickOperating('Project', item)">+ {{ $t("Headbar.3ba@acceding") }}</b-button>
              <b-button pill class="mt-4 selectedActive" v-else>
                <i class="ri-check-line"></i>{{ $t("Headbar.3ba@acceding") }}
              </b-button> -->
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- Award -->
      <iq-card v-if="resultData.Award && resultData.Award.length">
        <template v-slot:headerTitle>
          <h5 class="m-0">{{ $t("Headbar.439@award") }}</h5>
        </template>
        <template v-slot:body>
          <ul class="p-0 m-0">
            <li class="user-box" v-for="(item, index) in resultData.Award" :key="index"
              @click="JumpDetails(item,'Award')">
              <div class="user-left d-flex align-items-center">
                <div class="user-img">
                  <!-- <img class="avatar-60 rounded-circle" :src="item.thump" /> -->
                  <el-avatar :size="60" :src="item.thump" @error="true">
                    <img src="@/assets/images/public/user.png" />
                  </el-avatar>
                </div>
                <div class="user-name">
                  <h3>{{ item.title }}</h3>
                  <p class="mb-0">{{ item.description }}</p>
                </div>
              </div>
              <p class="m-0">{{ item.start_time }}</p>
            </li>
            <!-- <li class="user-box">
              <p>See All</p>
            </li> -->
          </ul>
        </template>
      </iq-card>
      <!-- JCI  -->
      <!-- <iq-card>
        <div class="search-event">
          <div class="avatar_box_self">
            <img class="avatar-70" src="@/assets/images/user/user-05.jpg" />
          </div>
          <div class="event-info">
            <h3>JCI Victory</h3>
            <p class="m-0">Online Event · 15,000 Enrolled</p>
          </div>
        </div>
      </iq-card> -->

      <iq-card>
        <section class="d-flex justify-content-center align-items-center" style="background:#fff;height: 632px;" v-if="show">
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" v-if="!loading" />
        </section>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
  import SocialPost from "@/components/ComComponents/ComPost/SocialPost1";
  export default {
    data() {
      return {
        currentType: "All",
        activeName: 1,
        show: false,
        loading: false,
        resultData: {
          User: [],
          InterstClub: [],
          Channel: [],
          Post: [],
          Course: [],
          Meeting: [],
          Event: [],
          Project: [],
          Award: [],
          Program: [],
        },
        // socialPosts: Posts,
        leftList: [{
          id: 0,
          icon: "ri-article-line",
          name: "All",
          title: "Headbar.543@all",
        },
        {
          id: 1,
          icon: "ri-newspaper-line",
          name: "Post",
          title: "Headbar.f0f@post",
        },
        {
          id: 2,
          icon: "ri-user-line",
          name: "User",
          title: "Headbar.f31@user",
        },
        // {
        //   id: 7,
        //   icon: "ri-building-line",
        //   name: "Channel",
        //   title: "Headbar.08a@channel",
        // },
        {
          id: 5,
          icon: "las la-users",
          name: "InterstClub",
          title: "Sidebar.820@interest_club",
        },
        {
          id: 752,
          icon: "ri-book-2-line",
          name: "Course",
          title: "Headbar.c5c@course",
        },
        {
          id: 87,
          icon: "ri-slideshow-2-line",
          name: "Meeting",
          title: "Headbar.a4d@meeting",
        },
        {
          id: 54,
          icon: "ri-calendar-event-line",
          name: "Event",
          title: "Headbar.c60@event",
        },
        {
          id: 78,
          icon: "ri-function-line",
          name: "Project",
          title: "Headbar.015@project",
        },
        // {
        //   id: 79,
        //   icon: "ri-award-line",
        //   name: "Program",
        //   title: "Program",
        // },
          // {
          //   id: 6,
          //   icon: "ri-award-line",
          //   name: "Award",
          //   title: "Headbar.439@award",
          // },
          // {
          //   id: 99,
          //   icon: "la-shopping-bag",
          //   name: "webstore",
          //   title: "Headbar.b4e@webstore",
          // },
        ],
      };
    },

    components: {
      SocialPost
    },

    created() {
      this.getSearchResult(this.$route.query.keyword);
    },
    watch: {
      $route: {
        deep: true,
        handler() {
          let keyword = this.$route.query.keyword;
          this.getSearchResult(keyword);
        },
      },
    },
    methods: {
      handleClickOperating(type, item) {
        if (type === "User") {
          this.friendRequest(item);
        } else if (type === "InterstClub") {
          this.joinGroup(item);
        } else if (type === "Channel") {
          this.channelFollow(item);
        } else if (type === "Meeting") {
          this.meetingEnroll(item);
        } else if (type === "Event") {
          this.registerSalon(item);
        } else if (type === "Project") {
          this.projectAttend(item);
        }
      },
      // User   = > 添加好友
      friendRequest(item) {
        this.$http.friendRequest({
          user_id: item.user_id
        }).then((res) => {
          if (res.status == 200) { }
        });
      },
      // InterstClub  = >  加入俱乐部
      joinGroup(item) {
        this.$http.joinGroup({
          group_id: item.id
        }).then((res) => {
          if (res.status == 200) {
            this.$set(item, "is_join", false);
            this.$message.success(this.$t('Headbar.522@joined_successfully'));
          }
        });
      },
      // Channel  = > 频道关注
      channelFollow(item) {
        this.$http.channelFollow({
          channel_id: item.id
        }).then((res) => {
          if (res.status == 200) {
            this.$set(item, "is_follow", true);
          }
        });
      },
      // Meeting  = > 会议参加
      meetingEnroll(item) {
        let itemData = item;
        this.$confirm(
          this.$t('Headbar.b1c@this_action_will_par'),
          "Tips", {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
        )
          .then(() => {
            const data = {
              meeting_id: itemData.id,
            };
            this.$http.meetingEnroll(data).then((res) => {
              if (res.status == 200) {
                this.$set(itemData, "is_join", true);
              }
            });
          })
          .catch(() => {
            console.log("取消");
          });
      },
      // Event  = > 参加活动
      registerSalon(item) {
        const data = {
          activity_id: item.id,
        };
        this.$http.registerSalon(data).then((res) => {
          if (res.status == 200) {
            this.$set(item, "is_join", true);
          }
        });
      },
      // Project  = > 项目申请
      projectAttend(item) {
        const data = {
          project_id: item.id,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        this.$http.projectAttend(data).then((res) => {
          if (res.status == 200) {
            his.$set(item, "is_join", true);
          }
        });
      },
      // 点击切换类型
      async handleSearchType(item) {
        this.show = false
        Object.keys(this.resultData).forEach((v) => (this.resultData[v] = ""));
        this.currentType = item.name;
        await this.getSearchResult(this.$route.query.keyword);
      },
      // 全局搜索返回结果
      async getSearchResult(keyword) {
        this.loading = true
        const data = {
          keyword,
          type: this.currentType,
        };
        let res = await this.$http.getSearchResult(data);
        if (res.status == 200) {
          this.loading = false
          if (this.currentType === "All") {
            res.data.forEach((item, index) => {
              this.resultData[item.type] = item.data;
            });
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.data.length) {
                this.show = false
                return
              } else {
                this.show = true
              }
            }
          } else {
            this.resultData[this.currentType] = res.data.data;
            if (this.resultData[this.currentType].length) {
              this.show = false
            } else {
              this.show = true
            }
            // console.log(this.currentType);
            // console.log('this.resultData[this.currentType]', this.resultData[this.currentType]);
            // console.log(this.resultData);

          }
        }
        this.loading = false
      },
      //跳 项目 详情
      JumpDetails(item, type) {
        // console.log('type', type);
        if (type == 'Project') {
          this.$router.push({
            path: "/project-detail",
            query: {
              id: item.id,
              type: 1
            }
          })
        } else if (type == 'Meeting') {
          this.$router.push({
            path: "/meeting-details",
            query: {
              id: item.id,
              type: 4
            }
          })
        } else if (type == 'Course') {
          this.$router.push({
            path: "/course-detail",
            query: {
              id: item.id,
              type: 3
            }
          })
        } else if (type == 'Event') {
          this.$router.push({
            path: "/event-detail",
            query: {
              id: item.id,
              type: 2
            }
          })
        } else if (type == 'Program') {
          this.$router.push({
            path: "/award-detail",
            query: {
              id: item.id,
            }
          })
        }

      }
    },
  };

</script>
<style lang="scss" scoped>
@media(min-width: 600px) {
  .phoneTab{
    display: none;
  }
}
@media(max-width: 600px) {
  .btn {
    font-size: 12px !important;
  }
  .pcTab {
    display: none;
  }
  ::v-deep .el-collapse-item__header{
    font-size: 16px;
    margin-left: 15px;
    font-weight: 400;
  }
  ::v-deep .el-collapse-item__content{
    padding-bottom: 0;
  }
  ::v-deep .el-collapse{
    border-top: none;
  }
}
  #search_container {
    ::v-deep .el-loading-mask {
      height: 632px;
      border-radius:5px;
      left: 7.5px;
    }
  }

  .iq-header-title {
    >h5 {
      font-size: 20px;
      color: #333;
    }
  }

  .left-filter {
    padding: 16px;
    padding-bottom: 0;
    font-size: 16px;
    color: #333;
  }

  .icon-box-left {
    >li {
      display: flex;
      align-items: center;
      padding: 8px 11px;
      margin-left: 8px;
      cursor: pointer;

      i {
        line-height: 36px;
      }

      &:hover {
        background-color: #f5fbff;

        .icon-box {
          background: #50b5ff;

          i {
            line-height: 36px;
          }
        }
      }

      div.icon-box {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        background: #ddd;
        margin-right: 16px;

        i {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }

  .user-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    padding: 17px 0;

    .user-left .user-name {
      margin-left: 16px;

      h3 {
        font-size: 16px;
      }

      p {
        color: #999;
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;

      >p {
        color: #50b5ff;
        margin: 0 auto;
      }
    }
  }

  .selectedActive {
    pointer-events: none;
    cursor: default;
    background: #a09e9e;
  }

  .search-event {
    padding: 10px;
    display: flex;
    align-items: center;

    >.avatar_box_self {
      width: 70px;
      height: 70px;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 14px;
    }

    >.event-info {
      >span {
        font-size: 12px;
        color: #999;
      }

      >h3 {
        font-size: 16px;
        color: #333;
      }

      >p {
        color: #666;
      }
    }
  }
</style>